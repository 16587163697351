import React, { useEffect, useMemo, useRef, useState } from "react";
import { PageMode } from ".";
import { MIN_HOURLY_SALARY, MIN_SALARY } from "../../../fixtures/jobpost";
import {
  ApplyMethod,
  ATCNames,
  Day,
  JobOfferLicenseType,
  JobOfferSalaryType,
  JobOfferTaxType,
  JobPostRegistrationForm,
  machineNames,
  softwareNames,
  welfareTagsName,
} from "../../../models/Model.JobPost";
import { getKeyByValue } from "../../../utils/CommonUtil";
import {
  addHyphenToPhoneNumber,
  numberToUnit,
} from "../../../utils/JobPostUtil";
import Button from "../../atoms/Button";
import Input from "../../atoms/Input";
import Switch from "../../atoms/Switch/Switch";
import Text from "../../atoms/Text";
import ToggleButton from "../../atoms/ToggleButton/ToggleButton";
import { COLOR_SYSTEM } from "../../design/design-system";
import Dropdown from "../../molecules/Dropdown/Dropdown";
import { MainWrapper, SectionWrapper } from "./style";
import ChatInitializer from "../../chat/ChatInitializer";

interface MainProps {
  edit?: boolean;
  form?: JobPostRegistrationForm;
  jobPost?: any;
  onChangeForm?: React.Dispatch<React.SetStateAction<JobPostRegistrationForm>>;
  onChangeMode?: React.Dispatch<React.SetStateAction<PageMode>>;
  setEdited?: React.Dispatch<React.SetStateAction<boolean>>;
  handleOpenWorkTypeSheetClick?: () => void;
  onUpdateJobPost?: (form: JobPostRegistrationForm, postId: number) => void;
}

type RequiredFields =
  | "title"
  | "workday"
  | "workdayDetail"
  | "salaryType"
  | "salary"
  | "taxType"
  | "license"
  | "sw"
  | "atc";
type RequiredElement = {
  [key in RequiredFields]: HTMLElement | null;
};

const Main: React.FC<MainProps> = ({
  edit,
  form,
  jobPost,
  onChangeForm,
  onChangeMode,
  setEdited,
  handleOpenWorkTypeSheetClick,
  onUpdateJobPost,
}) => {
  const workTypeCoupler = useMemo(
    () => ({
      "+A1": "풀타임 - 일 8시간, 주 5일 이상",
      "+A2": "파트타임 - 일 8시간, 주 5일 미만",
      "+B": "단기알바 - 3개월 이내 단기간",
    }),
    []
  );
  const checkIsEmpty =
    !form.workType ||
    !form.title.trim() ||
    !form.workDayOfWeek.includes(true) ||
    !form.workTime.trim() ||
    form.taxType === JobOfferTaxType.NONE ||
    form.salaryType === JobOfferSalaryType.NONE ||
    !form.salaryRangeLow ||
    form.salaryRangeLow === "0" ||
    !form.salaryRangeHigh ||
    form.salaryRangeHigh === "0" ||
    form.needLicense === JobOfferLicenseType.INVALID ||
    !form.sw ||
    (form.sw === "기타" && !form.swEtc.trim()) ||
    !form.atc ||
    !form.phone;

  const mainEl = useRef<HTMLDivElement>(null);

  const [errors, setErrors] = useState({
    emptyTitle: false,
    emptyWorkDayOfWeek: false,
    emptyWorkTime: false,
    emptySalaryType: false,
    emptyLowSalary: false,
    emptyHighSalary: false,
    hourlySalaryLowTooHighError: false,
    hourlySalaryHighTooHighError: false,
    salaryTooLowError: false,
    salaryRangeLowError: false,
    salaryRangeHighError: false,
    emptyTaxType: false,
    emptyNeedLicense: false,
    emptySw: false,
    emptyAtc: false,
  });

  const inputFieldEls = useRef<RequiredElement>({
    title: null,
    workday: null,
    workdayDetail: null,
    salaryType: null,
    salary: null,
    taxType: null,
    license: null,
    sw: null,
    atc: null,
  });

  const setRef = (key: RequiredFields) => (el: HTMLElement) => {
    if (inputFieldEls.current) inputFieldEls.current[key] = el;
  };

  const renderWorkDays = () => {
    const dayCoupler = ["월", "화", "수", "목", "금", "토", "일"];
    const days = [];

    for (let day = Day.Monday; day <= Day.Sunday; day++) {
      days.push(
        <ToggleButton
          key={day}
          color="Skyblue"
          size="Medium"
          type="Text"
          variant="Tinted"
          active={form.workDayOfWeek[day]}
          onClick={handleWorkDayOfWeek(day)}
        >
          {dayCoupler[day]}
        </ToggleButton>
      );
    }

    return days;
  };

  const renderSalaryTypes = () => {
    const salaryCoupler = [
      {
        title: "시급",
        salaryType: JobOfferSalaryType.HOURLY,
      },
      {
        title: "일급",
        salaryType: JobOfferSalaryType.DAILY,
      },
      {
        title: "주급",
        salaryType: JobOfferSalaryType.WEEKLY,
      },
      {
        title: "월급",
        salaryType: JobOfferSalaryType.MONTHLY,
      },
    ];
    const salaryTypes = [];

    for (let type = 0; type < salaryCoupler.length; type++) {
      const { title, salaryType } = salaryCoupler[type];

      salaryTypes.push(
        <ToggleButton
          key={type}
          color="Skyblue"
          size="Medium"
          type="Text"
          variant={
            form.salaryType === salaryType ? "OutlinedTinted" : "Outlined"
          }
          active={form.salaryType === salaryType}
          onClick={handleSalaryType(salaryType)}
        >
          {title}
        </ToggleButton>
      );
    }

    return salaryTypes;
  };

  const renderWelfareTags = () => {
    const welfareTags = [];

    for (let i = 0; i < welfareTagsName.length; i++) {
      welfareTags.push(
        <ToggleButton
          key={i}
          color="Skyblue"
          size="Medium"
          type="Text"
          variant="Tinted"
          active={form.welfare[i]}
          onClick={handleWelfareTag(i)}
        >
          {welfareTagsName[i]}
        </ToggleButton>
      );
    }

    return welfareTags;
  };

  const renderSoftwareTags = () => {
    const softwareTags = [];

    softwareNames.forEach((tag) => {
      softwareTags.push(
        <ToggleButton
          key={tag}
          color="Skyblue"
          size="Medium"
          type="Text"
          variant={form.sw === tag ? "OutlinedTinted" : "Outlined"}
          active={form.sw === tag}
          onClick={handleSoftwareTag(tag)}
        >
          {tag}
        </ToggleButton>
      );
    });

    return softwareTags;
  };

  const renderACTTags = () => {
    const ACTTags = [];

    ATCNames.forEach((tag) => {
      ACTTags.push(
        <ToggleButton
          key={tag}
          color="Skyblue"
          size="Medium"
          type="Text"
          variant={form.atc === tag ? "OutlinedTinted" : "Outlined"}
          active={form.atc === tag}
          onClick={handleACTTag(tag)}
        >
          {tag}
        </ToggleButton>
      );
    });

    return ACTTags;
  };

  const renderMachines = () => {
    const machineTags = [];

    for (let i = 0; i < machineNames.length; i++) {
      machineTags.push(
        <ToggleButton
          key={i}
          color="Skyblue"
          size="Medium"
          type="Text"
          variant="Tinted"
          active={form.machine[i]}
          onClick={handleMachineTag(i)}
        >
          {machineNames[i]}
        </ToggleButton>
      );
    }

    return machineTags;
  };

  const handleWorkDayOfWeek = (day: Day) => () => {
    const current = [...form.workDayOfWeek];
    current[day] = !current[day];
    setEdited(true);
    setErrors({ ...errors, emptyWorkDayOfWeek: false });
    onChangeForm({ ...form, workDayOfWeek: current });
  };

  const handleSalaryType = (salaryType: JobOfferSalaryType) => () => {
    setEdited(true);
    setErrors({ ...errors, emptySalaryType: false });
    onChangeForm({ ...form, salaryType });
  };

  const handleNeedLicense = (needLicense: JobOfferLicenseType) => () => {
    setEdited(true);
    setErrors({ ...errors, emptyNeedLicense: false });
    onChangeForm({ ...form, needLicense });
  };

  const handleApplyMethod = (applyMethod: ApplyMethod) => () => {
    if (!form.applyMethod) form.applyMethod = ApplyMethod.None;
    const sortedApplyMethod = form.applyMethod.includes(applyMethod)
      ? form.applyMethod.replace(applyMethod, "")
      : `${form.applyMethod}${applyMethod}`.split("").sort().join("");
    const key = getKeyByValue(ApplyMethod, sortedApplyMethod);
    onChangeForm({ ...form, applyMethod: ApplyMethod[key] });
  };

  const handleWelfareTag = (index: number) => () => {
    const current = [...form.welfare];
    current[index] = !current[index];
    setEdited(true);
    onChangeForm({ ...form, welfare: current });
  };

  const handleSoftwareTag = (sw: string) => () => {
    setEdited(true);
    setErrors({ ...errors, emptySw: false });
    onChangeForm({ ...form, sw, swEtc: "" });
  };

  const handleACTTag = (atc: string) => () => {
    setEdited(true);
    setErrors({ ...errors, emptyAtc: false });
    onChangeForm({ ...form, atc });
  };

  const handleMachineTag = (index: number) => () => {
    const current = [...form.machine];
    current[index] = !current[index];
    setEdited(true);
    if (index === form.machine.length - 1 && !current[index]) {
      onChangeForm({ ...form, machine: current, machineEtc: "" });
    } else {
      onChangeForm({ ...form, machine: current });
    }
  };

  const handleClearInput = (key: keyof typeof form) => () => {
    setEdited(true);
    onChangeForm({ ...form, [key]: "" });
  };

  const handleSwitchClick = (key: keyof typeof form) => () => {
    const origin = form[key];
    setEdited(true);
    onChangeForm({ ...form, [key]: !origin });
  };

  const setSalaryError = (name, value) => {
    const minSalary = parseInt(form.salaryRangeLow.replace(/,/g, ""));
    const maxSalary = parseInt(form.salaryRangeHigh.replace(/,/g, ""));
    const inputSalary = parseInt(value.replace(/,/g, ""));

    setErrors({
      ...errors,
      emptyLowSalary: false,
      emptyHighSalary: false,
      salaryRangeLowError: false,
      salaryRangeHighError: false,
    });

    if (name === "salaryRangeLow") {
      if (maxSalary && inputSalary > maxSalary) {
        setErrors({ ...errors, salaryRangeLowError: true });
      }
    } else if (name === "salaryRangeHigh") {
      if (minSalary && inputSalary < minSalary) {
        setErrors({ ...errors, salaryRangeHighError: true });
      }
    }
  };

  const handleInput = (onlyNumber = false, money = false) => (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value, name } = e.target;

    setEdited(true);

    if (money) {
      setSalaryError(name, value);
      onChangeForm({
        ...form,
        [name]: Number(value.replace(/[^0-9]/g, "")).toLocaleString(),
      });
    } else {
      onlyNumber
        ? onChangeForm({ ...form, [name]: value.replace(/[^0-9]/g, "") })
        : onChangeForm({ ...form, [name]: value });
    }
  };

  const scrollToErrorField = (
    field: RequiredFields,
    error?: keyof typeof errors
  ) => {
    mainEl.current.scrollTo({
      top: inputFieldEls.current[field].offsetTop - 60,
      behavior: "smooth",
    });
    if (error)
      setErrors({
        ...errors,
        emptyTitle: false,
        emptyWorkDayOfWeek: false,
        emptyWorkTime: false,
        emptySalaryType: false,
        emptyLowSalary: false,
        emptyHighSalary: false,
        salaryTooLowError: false,
        salaryRangeLowError: false,
        hourlySalaryHighTooHighError: false,
        hourlySalaryLowTooHighError: false,
        salaryRangeHighError: false,
        emptyTaxType: false,
        emptyNeedLicense: false,
        emptySw: false,
        emptyAtc: false,
        [error]: true,
      });
    return false;
  };

  const checkValidation = () => {
    if (!Object.keys(inputFieldEls.current).length) return;

    // 제목 입력 X
    if (!form.title.trim()) return scrollToErrorField("title", "emptyTitle");
    // 근무 요일 입력 X
    if (!form.workDayOfWeek.includes(true))
      return scrollToErrorField("workday", "emptyWorkDayOfWeek");
    // 상세 일정 입력 X
    if (!form.workTime.trim())
      return scrollToErrorField("workdayDetail", "emptyWorkTime");
    // 급여 유형 입력 X
    if (form.salaryType === JobOfferSalaryType.NONE)
      return scrollToErrorField("salaryType", "emptySalaryType");
    // 최소 급여 입력 X
    if (!parseInt(form.salaryRangeLow.replace(/,/g, "")))
      return scrollToErrorField("salary", "emptyLowSalary");
    // 최대 급여 입력 X
    if (!parseInt(form.salaryRangeHigh.replace(/,/g, "")))
      return scrollToErrorField("salary", "emptyHighSalary");
    // 너무 낮은 최저 급여 입력
    if (Number(form.salaryRangeLow.replace(/,/g, "")) < MIN_SALARY)
      return scrollToErrorField("salary", "salaryTooLowError");
    // 너무 높은 최고 시급 입력
    if (
      form.salaryType === JobOfferSalaryType.HOURLY &&
      Number(form.salaryRangeHigh.replace(/,/g, "")) > MIN_HOURLY_SALARY
    )
      return scrollToErrorField("salary", "hourlySalaryHighTooHighError");
    // 너무 높은 최저 시급 입력
    if (
      form.salaryType === JobOfferSalaryType.HOURLY &&
      Number(form.salaryRangeLow.replace(/,/g, "")) > MIN_HOURLY_SALARY
    )
      return scrollToErrorField("salary", "hourlySalaryLowTooHighError");
    // 최소 금액 > 최대 금액
    if (
      parseInt(form.salaryRangeLow.replace(/,/g, "")) >
      parseInt(form.salaryRangeHigh.replace(/,/g, ""))
    )
      return scrollToErrorField("salary", "salaryRangeHighError");
    // 실수령액 여부 입력 X
    if (form.taxType === JobOfferTaxType.NONE)
      return scrollToErrorField("taxType", "emptyTaxType");
    // 심평원 등록 여부 입력 X
    if (form.needLicense === JobOfferLicenseType.INVALID)
      return scrollToErrorField("license", "emptyNeedLicense");
    // 전산 소프트웨어 입력 X
    if (!form.sw || (form.sw === "기타" && !form.swEtc.trim()))
      return scrollToErrorField("sw", "emptySw");
    // ATC 입력 X
    if (!form.atc) return scrollToErrorField("atc", "emptyAtc");

    return true;
  };

  const handleSubmitClick = (edit: boolean = false) => () => {
    if (!checkValidation()) return;
    edit ? onUpdateJobPost(form, jobPost.id) : onChangeMode("Preview");
  };

  const handleSelect = (
    field: keyof typeof form,
    value: any,
    error: keyof typeof errors
  ) => () => {
    setEdited(true);
    setErrors({ ...errors, [error]: false });
    onChangeForm({ ...form, [field]: value });
  };

  const renderSalaryHint = (type: "low" | "high") => {
    if (type === "low") {
      if (errors.emptyLowSalary) {
        return "최소 급여를 입력해주세요";
      }
      if (errors.salaryRangeLowError) {
        return `${
          numberToUnit(Number(form.salaryRangeHigh.replace(/,/g, ""))) || 0
        }원 이하로 입력해주세요`;
      } else if (errors.salaryTooLowError) {
        return `최소 2만원 이상 입력해주세요`;
      } else if (errors.hourlySalaryLowTooHighError) {
        return `최대 7만원 이하로 입력해주세요`;
      } else {
        return `최소 ${
          numberToUnit(Number(form.salaryRangeLow.replace(/,/g, ""))) || 0
        }원`;
      }
    } else {
      if (errors.emptyHighSalary) {
        return "최대 급여를 입력해주세요";
      }
      if (errors.salaryRangeHighError) {
        return `${numberToUnit(
          Number(form.salaryRangeLow.replace(/,/g, ""))
        )}원 이상으로 입력해주세요`;
      } else if (errors.hourlySalaryHighTooHighError) {
        return `최소 7만원 이하로 입력해주세요`;
      } else {
        return `최대 ${
          numberToUnit(Number(form.salaryRangeHigh.replace(/,/g, ""))) || 0
        }원`;
      }
    }
  };

  useEffect(() => {
    if (!form.applyMethod.includes(ApplyMethod.Message)) {
      onChangeForm({ ...form, phonePersonInCharge: "" });
    }
  }, [form.applyMethod]);

  return (
    <>
      <MainWrapper
        ref={mainEl}
        edit={edit}
        onClick={() => {
          (document.querySelector(".ion-page") as HTMLElement).blur();
        }}
      >
        <SectionWrapper className="pharmacy-info">
          <Text textType="H4" color={COLOR_SYSTEM.get("Gray")[900]}>
            {form.name}
          </Text>
          <Text
            element="p"
            textType="Body1"
            color={COLOR_SYSTEM.get("Gray")[500]}
            className="pharmacy-info__address"
          >
            {form.address}
          </Text>
          <Text
            element="p"
            textType="Body1"
            color={COLOR_SYSTEM.get("Gray")[500]}
          >
            {addHyphenToPhoneNumber(form.phone)}
          </Text>
        </SectionWrapper>
        <SectionWrapper>
          <Dropdown
            label="근무 유형"
            placeholder="근무 유형을 선택하세요"
            value={workTypeCoupler[form.workType]}
            required
            onClick={handleOpenWorkTypeSheetClick}
          />
        </SectionWrapper>
        <SectionWrapper ref={setRef("title")}>
          <Input
            label="공고 제목"
            value={form.title}
            name={"title"}
            onChange={handleInput()}
            onClick={() => {
              setErrors({ ...errors, emptyTitle: false });
            }}
            onClear={handleClearInput("title")}
            error={errors.emptyTitle}
            hint={errors.emptyTitle ? "공고 제목을 입력해주세요" : ""}
            required
          />
        </SectionWrapper>
        <SectionWrapper ref={setRef("workday")} className="workday">
          <div className="workday__title">
            <div>
              <Text
                textType="InputSmall"
                color={
                  errors.emptyWorkDayOfWeek
                    ? COLOR_SYSTEM.get("Red")[300]
                    : COLOR_SYSTEM.get("Gray")[600]
                }
              >
                근무 요일
              </Text>
              <Text
                textType="InputSmall"
                color={COLOR_SYSTEM.get("Red")[300]}
                className="title__required"
              >
                *
              </Text>
            </div>
            <Switch
              label="협의 가능"
              labelPosition="Left"
              size="Small"
              isActive={form.canNegoWorkDayOfWeek}
              onClick={handleSwitchClick("canNegoWorkDayOfWeek")}
            />
          </div>
          <div className="workday__button-list">{renderWorkDays()}</div>
          {errors.emptyWorkDayOfWeek && (
            <Text
              textType="InputSmall"
              color={COLOR_SYSTEM.get("Red")[400]}
              className="error-message"
            >
              근무 요일을 선택해주세요
            </Text>
          )}
        </SectionWrapper>
        <SectionWrapper ref={setRef("workdayDetail")}>
          <div className="worktime__title">
            <div>
              <Text
                textType="InputSmall"
                color={
                  errors.emptyWorkTime
                    ? COLOR_SYSTEM.get("Red")[300]
                    : COLOR_SYSTEM.get("Gray")[600]
                }
              >
                상세 일정
              </Text>
              <Text
                textType="InputSmall"
                color={COLOR_SYSTEM.get("Red")[300]}
                className="title__required"
              >
                *
              </Text>
            </div>
            <Switch
              label="협의 가능"
              labelPosition="Left"
              size="Small"
              isActive={form.canNegoWorkTime}
              onClick={handleSwitchClick("canNegoWorkTime")}
            />
          </div>
          <Input
            value={form.workTime}
            placeholder={
              <Text textType="InputLarge" color={COLOR_SYSTEM.get("Gray")[300]}>
                구체적인 근무기간과 시간을 알려주세요.
                <br />
                <br />
                예)
                <br />
                7월1일~12월31일 6개월 근무이며,
                <br />
                근무시간은 월,수,금 09시~19시30분 입니다. (9.5시간, 점심1시간
                제외)
              </Text>
            }
            name={"workTime"}
            className="worktime__input"
            multiLine
            required
            error={errors.emptyWorkTime}
            hint={errors.emptyWorkTime ? "상세 일정을 입력해주세요" : ""}
            onChange={handleInput()}
            onClick={() => {
              setErrors({ ...errors, emptyWorkTime: false });
            }}
          />
        </SectionWrapper>
        <SectionWrapper ref={setRef("salaryType")}>
          <div className="salary__title">
            <div>
              <Text
                textType="InputSmall"
                color={
                  errors.emptySalaryType
                    ? COLOR_SYSTEM.get("Red")[300]
                    : COLOR_SYSTEM.get("Gray")[600]
                }
              >
                급여 유형
              </Text>
              <Text
                textType="InputSmall"
                color={COLOR_SYSTEM.get("Red")[300]}
                className="title__required"
              >
                *
              </Text>
            </div>
          </div>
          <div className="salary__button-list">{renderSalaryTypes()}</div>
          {errors.emptySalaryType && (
            <Text textType="InputSmall" color={COLOR_SYSTEM.get("Red")[400]}>
              급여 유형을 입력해주세요
            </Text>
          )}
        </SectionWrapper>
        <SectionWrapper
          taxTypeError={errors.emptyTaxType}
          ref={setRef("salary")}
        >
          <div className="salary__title">
            <div>
              <Text
                textType="InputSmall"
                color={
                  errors.emptyLowSalary ||
                  errors.emptyHighSalary ||
                  errors.salaryRangeLowError ||
                  errors.salaryRangeHighError ||
                  errors.salaryTooLowError ||
                  errors.hourlySalaryHighTooHighError ||
                  errors.hourlySalaryLowTooHighError
                    ? COLOR_SYSTEM.get("Red")[300]
                    : COLOR_SYSTEM.get("Gray")[600]
                }
              >
                급여
              </Text>
              <Text
                textType="InputSmall"
                color={COLOR_SYSTEM.get("Red")[300]}
                className="title__required"
              >
                *
              </Text>
            </div>
            <Switch
              label="협의 가능"
              labelPosition="Left"
              size="Small"
              isActive={form.canNegoSalary}
              onClick={handleSwitchClick("canNegoSalary")}
            />
          </div>
          <Text
            textType="Caption"
            color={COLOR_SYSTEM.get("Skyblue")[500]}
            className="salary__description"
          >
            최대한 정확하게 입력해주세요.
          </Text>
          <div className="salary__input">
            <Input
              type="tel"
              value={form.salaryRangeLow}
              name="salaryRangeLow"
              onChange={handleInput(true, true)}
              placeholder="최소"
              error={
                errors.emptyLowSalary ||
                errors.salaryRangeLowError ||
                errors.salaryTooLowError ||
                errors.hourlySalaryLowTooHighError
              }
              hint={renderSalaryHint("low")}
              rightItems={[
                <Text
                  textType="InputLarge"
                  color={COLOR_SYSTEM.get("Gray")[800]}
                >
                  원
                </Text>,
              ]}
            />
            <Text
              textType="InputLarge"
              color={COLOR_SYSTEM.get("Gray")[800]}
              style={{ marginTop: "10px" }}
            >
              ~
            </Text>
            <Input
              type="tel"
              value={form.salaryRangeHigh}
              name="salaryRangeHigh"
              onChange={handleInput(true, true)}
              placeholder="최대"
              error={
                errors.emptyHighSalary ||
                errors.salaryRangeHighError ||
                errors.hourlySalaryHighTooHighError
              }
              hint={renderSalaryHint("high")}
              rightItems={[
                <Text
                  textType="InputLarge"
                  color={COLOR_SYSTEM.get("Gray")[800]}
                >
                  원
                </Text>,
              ]}
            />
          </div>

          <div className="salary__tax-type" ref={setRef("taxType")}>
            <div className="tax__title">
              <Text
                textType="InputSmall"
                color={
                  errors.emptyTaxType
                    ? COLOR_SYSTEM.get("Red")[300]
                    : COLOR_SYSTEM.get("Gray")[600]
                }
              >
                위 급여가 약사가 받는 실수령액인가요?
              </Text>
              <Text
                textType="InputSmall"
                color={COLOR_SYSTEM.get("Red")[300]}
                className="title__required"
              >
                *
              </Text>
            </div>
            <div className="tax__buttons">
              <ToggleButton
                color="Skyblue"
                size="Medium"
                type="Text"
                variant={
                  form.taxType === JobOfferTaxType.AFTER_TAX
                    ? "OutlinedTinted"
                    : "Outlined"
                }
                active={form.taxType === JobOfferTaxType.AFTER_TAX}
                onClick={handleSelect(
                  "taxType",
                  JobOfferTaxType.AFTER_TAX,
                  "emptyTaxType"
                )}
              >
                예 (세후, 네트제)
              </ToggleButton>
              <ToggleButton
                color="Skyblue"
                size="Medium"
                type="Text"
                variant={
                  form.taxType === JobOfferTaxType.PRE_TAX
                    ? "OutlinedTinted"
                    : "Outlined"
                }
                active={form.taxType === JobOfferTaxType.PRE_TAX}
                onClick={handleSelect(
                  "taxType",
                  JobOfferTaxType.PRE_TAX,
                  "emptyTaxType"
                )}
              >
                아니오 (세전)
              </ToggleButton>
            </div>
            {errors.emptyTaxType && (
              <Text textType="InputSmall" color={COLOR_SYSTEM.get("Red")[400]}>
                실수령액 여부를 선택해주세요
              </Text>
            )}
          </div>
        </SectionWrapper>
        <SectionWrapper ref={setRef("license")} className="need-license">
          <div>
            <Text
              textType="InputSmall"
              color={
                errors.emptyNeedLicense
                  ? COLOR_SYSTEM.get("Red")[300]
                  : COLOR_SYSTEM.get("Gray")[600]
              }
            >
              면허 심평원 등록 여부
            </Text>
            <Text
              textType="InputSmall"
              color={COLOR_SYSTEM.get("Red")[300]}
              className="title__required"
            >
              *
            </Text>
          </div>
          <div className="need-license__button-list">
            <ToggleButton
              color="Skyblue"
              size="Medium"
              type="Text"
              variant={
                form.needLicense === JobOfferLicenseType.NECESSARY
                  ? "OutlinedTinted"
                  : "Outlined"
              }
              active={form.needLicense === JobOfferLicenseType.NECESSARY}
              onClick={handleNeedLicense(JobOfferLicenseType.NECESSARY)}
            >
              필요
            </ToggleButton>
            <ToggleButton
              color="Skyblue"
              size="Medium"
              type="Text"
              variant={
                form.needLicense === JobOfferLicenseType.UNNECESSARY
                  ? "OutlinedTinted"
                  : "Outlined"
              }
              active={form.needLicense === JobOfferLicenseType.UNNECESSARY}
              onClick={handleNeedLicense(JobOfferLicenseType.UNNECESSARY)}
            >
              불필요
            </ToggleButton>
          </div>
          {errors.emptyNeedLicense && (
            <Text textType="InputSmall" color={COLOR_SYSTEM.get("Red")[400]}>
              면허 심평원 등록 여부를 선택해주세요
            </Text>
          )}
        </SectionWrapper>
        <SectionWrapper>
          <div className="apply-method__desc">
            <Text textType="InputSmall" color={COLOR_SYSTEM.get("Gray")[600]}>
              지원 방법 (복수 선택 가능)
            </Text>
            <Text textType="Caption" color={COLOR_SYSTEM.get("Skyblue")[500]}>
              약문약답 채팅을 이용하시면 수많은 문자 목록을 뒤지지 않아도
              지원자만 따로 모아 볼 수 있어요.
            </Text>
          </div>
          <div className="apply-method__button-list">
            <ToggleButton
              color="Skyblue"
              size="Medium"
              type="IconWithText"
              icon="Check"
              right
              variant="Tinted"
              active={form.applyMethod.includes(ApplyMethod.Chat)}
              onClick={handleApplyMethod(ApplyMethod.Chat)}
            >
              약문약답 채팅으로 지원받기
            </ToggleButton>
            <ToggleButton
              color="Skyblue"
              size="Medium"
              type="IconWithText"
              icon="Check"
              right
              variant="Tinted"
              active={form.applyMethod.includes(ApplyMethod.Message)}
              onClick={handleApplyMethod(ApplyMethod.Message)}
            >
              휴대폰 문자로 지원받기
            </ToggleButton>
            {form.applyMethod.includes(ApplyMethod.Chat) && <ChatInitializer />}
          </div>
        </SectionWrapper>
        {form.applyMethod.includes(ApplyMethod.Message) && (
          <SectionWrapper>
            <Input
              type="tel"
              value={form.phonePersonInCharge}
              label="문자 받을 전화번호"
              name="phonePersonInCharge"
              onChange={handleInput(true)}
              onClear={handleClearInput("phonePersonInCharge")}
            />
          </SectionWrapper>
        )}
        <SectionWrapper>
          <Input
            value={form.message}
            name="message"
            label="근무 참고사항"
            placeholder={
              <Text textType="InputLarge" color={COLOR_SYSTEM.get("Gray")[300]}>
                예) 이비인후과, 소아과 처방 위주입니다.
                <br />
                복약지도 잘 하실 수 있는 약사님이면 좋겠어요.
              </Text>
            }
            multiLine
            onChange={handleInput()}
          />
        </SectionWrapper>
        <SectionWrapper className="pharmacistCnt">
          <Text
            textType="InputSmall"
            color={
              errors.emptyNeedLicense
                ? COLOR_SYSTEM.get("Red")[300]
                : COLOR_SYSTEM.get("Gray")[600]
            }
          >
            근무 인원 (약사/직원)
          </Text>
          <div className="pharmacistCnt__input">
            <Input
              type="tel"
              value={form.pharmacistCnt}
              name="pharmacistCnt"
              placeholder="약사"
              onChange={handleInput(true)}
              rightItems={[
                <Text
                  textType="InputLarge"
                  color={COLOR_SYSTEM.get("Gray")[800]}
                >
                  명
                </Text>,
              ]}
            />
            <Input
              type="tel"
              value={form.employeeCnt}
              name="employeeCnt"
              placeholder="직원"
              onChange={handleInput(true)}
              rightItems={[
                <Text
                  textType="InputLarge"
                  color={COLOR_SYSTEM.get("Gray")[800]}
                >
                  명
                </Text>,
              ]}
            />
          </div>
          <Text textType="InputSmall" color={COLOR_SYSTEM.get("Gray")[400]}>
            * 채용할 약사가{" "}
            <Text
              element="strong"
              textType="InputSmall"
              color={COLOR_SYSTEM.get("Gray")[800]}
            >
              함께 근무할 인원 수
            </Text>
            를 기입해주세요
          </Text>
        </SectionWrapper>
        <SectionWrapper>
          <Text
            textType="InputSmall"
            color={COLOR_SYSTEM.get("Gray")[600]}
            className="welfare__title"
          >
            복리후생
          </Text>
          <div className="welfare__button-list">{renderWelfareTags()}</div>
          <Input
            value={form.welfareDetail}
            name="welfareDetail"
            placeholder={
              <Text textType="InputLarge" color={COLOR_SYSTEM.get("Gray")[300]}>
                추가하실 복지혜택이 있으신 경우
                <br />
                작성해주세요.
              </Text>
            }
            multiLine
            onChange={handleInput()}
          />
        </SectionWrapper>
        <SectionWrapper ref={setRef("sw")}>
          <div>
            <Text
              textType="InputSmall"
              color={
                errors.emptySw
                  ? COLOR_SYSTEM.get("Red")[300]
                  : COLOR_SYSTEM.get("Gray")[600]
              }
            >
              전산 소프트웨어
            </Text>
            <Text
              textType="InputSmall"
              color={COLOR_SYSTEM.get("Red")[300]}
              className="title__required"
            >
              *
            </Text>
          </div>
          <div className="software__button-list">{renderSoftwareTags()}</div>
          {form.sw === "기타" && (
            <div>
              <Input
                value={form.swEtc}
                name="swEtc"
                placeholder="사용하시는 소프트웨어를 입력해주세요"
                onChange={handleInput()}
                onClear={handleClearInput("swEtc")}
                onClick={() => {
                  setErrors({ ...errors, emptySw: false });
                }}
              />
            </div>
          )}

          {errors.emptySw && (
            <Text textType="InputSmall" color={COLOR_SYSTEM.get("Red")[400]}>
              사용하시는 전산 소프트웨어를 선택해주세요
            </Text>
          )}
        </SectionWrapper>
        <SectionWrapper ref={setRef("atc")}>
          <div>
            <Text
              textType="InputSmall"
              color={
                errors.emptyAtc
                  ? COLOR_SYSTEM.get("Red")[300]
                  : COLOR_SYSTEM.get("Gray")[600]
              }
            >
              자동 조제기 (ATC)
            </Text>
            <Text
              textType="InputSmall"
              color={COLOR_SYSTEM.get("Red")[300]}
              className="title__required"
            >
              *
            </Text>
          </div>
          <div className="atc__button-list">{renderACTTags()}</div>
          {errors.emptyAtc && (
            <Text textType="InputSmall" color={COLOR_SYSTEM.get("Red")[400]}>
              사용하시는 자동 조제기를 선택해주세요
            </Text>
          )}
        </SectionWrapper>
        <SectionWrapper>
          <Text textType="InputSmall" color={COLOR_SYSTEM.get("Gray")[600]}>
            기타 자동화기기
          </Text>
          <div className="machine__button-list">{renderMachines()}</div>
          {form.machine[form.machine.length - 1] && (
            <Input
              value={form.machineEtc}
              placeholder={
                <Text
                  textType="InputLarge"
                  color={COLOR_SYSTEM.get("Gray")[300]}
                >
                  기타 사용하시는 자동화기기를 적어주세요
                </Text>
              }
              name={"machineEtc"}
              multiLine
              onChange={handleInput()}
            />
          )}
        </SectionWrapper>
        {!edit && (
          <div>
            <Button
              color="Primary"
              size="Large"
              type="Text"
              variant="Contained"
              style={{ width: "100%" }}
              disabledColor={checkIsEmpty}
              onClick={handleSubmitClick()}
            >
              다음
            </Button>
          </div>
        )}
      </MainWrapper>
      {edit && (
        <div
          style={{
            padding: "0 24px",
            position: "fixed",
            left: 0,
            bottom: 0,
            backgroundColor: COLOR_SYSTEM.get("Gray")[0],
            width: "100%",
            height: "75px",
            lineHeight: "75px",
            zIndex: 201,
          }}
        >
          <Button
            color="Primary"
            size="Large"
            type="Text"
            variant="Contained"
            style={{ width: "100%" }}
            disabledColor={checkIsEmpty}
            onClick={handleSubmitClick(edit)}
          >
            수정하기
          </Button>
        </div>
      )}
    </>
  );
};

export default Main;
