import React, { Component, ReactNode, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { actions, RootState } from "../../store";
import {
  withRouter,
  RouteComponentProps,
  Switch,
  Route,
} from "react-router-dom";
import "./Chat.css";
import "./../../Common.css";
// import './../Common.css';
import { log, LogLevel } from "../../utils/LogUtil";
import * as API from "../../API.json";
import styled from "styled-components";
import Text from "../atoms/Text";

import {
  Channel,
  SendBirdProvider,
  sendBirdSelectors,
  SendBirdState,
  useSendbirdStateContext,
  withSendBird,
} from "sendbird-uikit";
import "sendbird-uikit/dist/index.css";
import ABTestUtil, { ABTestFeature } from "../../utils/ABTestUtil";
import { UserLevel, UserWorkType } from "../../models/Model.User";
import {
  getPayString,
  JobOfferInfo,
  JobOfferName,
  JobOfferStatus,
} from "../../models/Model.JobPost";
import AnalyticsUtil from "../../utils/AnalyticsUtil";
import { callPhone } from "../../utils/UrlSchemeUtil";
import { fetchAPI } from "../../utils/APIUtil";
import { getGlobal, GlobalKey } from "../../utils/GlobalUtil";
import { getDistanceString } from "../../utils/LocationUtil";
import { JobListType } from "../../store/jobpost/types";
import { COLOR_SYSTEM } from "../design/design-system";
import TopAppBar from "../molecules/TopAppBar/TopAppBar";
import Button from "../atoms/Button";
import Icon from "../atoms/Icon";
import ChatList from "./ChatList";
import Chat from "./Chat";
import { PrivateRoute } from "../../utils/Routing";
import StringUtil from "../../utils/StringUtil";
const queryString = require("query-string");

type Props = RouteComponentProps &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

type State = {
  name: string;
  phone: string;
  offer: JobOfferInfo;
};

class ChatMain extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    log(LogLevel.UI_LIFECYCLE, "Chat:componentDidMount");
  }

  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount(): void {}

  render() {
    log(LogLevel.UI_LIFECYCLE, "Home.render", this.state);

    const myColorSet = {
      "--sendbird-light-primary-500": "#00487c",
      "--sendbird-light-primary-400": "#4bb3fd",
      "--sendbird-light-primary-300": "#2D9CDB",
      "--sendbird-light-primary-200": "#0496ff",
      "--sendbird-light-primary-100": "#027bce",
    };

    let target = "";
    if (ABTestUtil.isDev()) {
      target = "DEV";
    }
    if (
      ABTestUtil.isTest(ABTestFeature.CHAT) &&
      this.props.me &&
      this.props.me.level >= UserLevel.NORMAL_USER
    ) {
      return (
        <SendBirdProvider
          appId="39C5C867-4869-40DA-9D12-9840569F454F"
          userId={target + this.props.me.id.toString()}
          nickname={this.props.me.nickname}
          profileUrl={StringUtil.convertFilePath(this.props.me.profileUrl)}
          colorSet={myColorSet}
        >
          <ChatWapper />
          <Route
            path="/chat/chatlist"
            component={ChatList as React.ComponentType<any>}
          />

          <Route
            path="/chat"
            exact={true}
            component={Chat as React.ComponentType<any>}
          />
        </SendBirdProvider>
      );
    }
    return null;
  }
}

const mapStateToProps = (state: RootState) => ({
  me: state.user.me,
});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChatMain)
);

const ChatWapper: React.FC = ({}) => {
  const sdk = sendBirdSelectors.getSdk(useSendbirdStateContext());
  const me = useSelector((state: RootState) => state.user.me);
  useEffect(() => {
    log(LogLevel.UI_EVENT, "ChatWapper:intializeSendbird", sdk, me);
    if (!sdk || !me || !sdk.currentUser) return;

    let user = sdk.currentUser;

    var data = {
      id: me.id.toString(),
      name: me.name,
      nickname: me.nickname,
      phone: me.phone,
    };

    if (!user.metaData["id"]) {
      user.createMetaData(data, (response, error) => {
        log(
          LogLevel.UI_EVENT,
          "ChatWapper:intializeSendbird sendbird initializing meta create",
          user.metaData,
          response,
          error
        );
      });
    } else {
      user.updateMetaData(data, true, (response, error) => {
        log(
          LogLevel.UI_EVENT,
          "ChatWapper:intializeSendbird sendbird initializing meta update",
          user.metaData,
          response,
          error
        );
      });
    }

    log(
      LogLevel.UI_EVENT,
      "ChatWapper:intializeSendbird sendbird initialized",
      user.metaData
    );
  }, [sdk]);

  return <div />;
};
