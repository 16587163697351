import { BoardContent, Attachment } from "../models/Model.Board";
import { LogLevel, log } from "./LogUtil";
import { fetchAPI } from "./APIUtil";
import * as API from "./../API.json";
import { getGlobal, GlobalKey } from "./GlobalUtil";
import StringUtil from "./StringUtil";
import { UserInterests } from "../models/Model.User";

export default class UserUtil {
  static getInterestString(interest: string) {
    if (!interest || interest === "-") {
      return "없음";
    }

    if (interest.length >= 14) return "모두";

    let rvalue = "";
    for (let i = 0; i < UserInterests.length; i++) {
      if (interest.includes(UserInterests[i].code)) {
        if (rvalue.length > 0) rvalue += ", ";
        rvalue += UserInterests[i].name;
      }
    }
    return rvalue;
  }

  static getPhoneNumberString(phone: string) {
    if (!phone) return "";
    phone = phone.replace(/-/gi, "");
    phone =
      phone.substring(0, 3) +
      "-" +
      phone.substring(3, 7) +
      "-" +
      phone.substring(7);
    return phone;
  }

  static hideLicense(str) {
    if (!str) return "";

    return str.replace(/./g, (c, i) => (i > 1 ? "*" : c));
  }

  static hideName(str) {
    if (!str) return "";
    return str.replace(/./g, (c, i) => (i % 2 == 1 ? "*" : c));
  }

  static hidePhoneNumber(phone) {
    if (!phone) return "";

    phone = phone.replace(/-/gi, "");
    phone = phone.substring(0, 3) + "-****-" + phone.substring(7);
    return phone;
  }

  static guessUserLicensedAt(licenseStr: string) {
    let license;
    try {
      license = parseInt(licenseStr);
    } catch (e) {
      return "0000";
    }
    if (!license) return "0000";

    if (LicensedYear[0] > license) return "1900";
    if (LicensedYear[LicensedYear.length - 1] <= license)
      return (LicensedYearStandard + LicensedYear.length - 1).toString();

    for (let i = 0; i < LicensedYear.length - 1; i++) {
      if (license >= LicensedYear[i] && license < LicensedYear[i + 1]) {
        return (LicensedYearStandard + i).toString();
      }
    }
  }
}

const LicensedYearStandard = 1986;
const LicensedYear = [
  31322, //1986
  32352, //1987
  34362, //1988
  35643, //1989
  36924, //1990
  38611, //1991
  39486, //1992
  40767, //1993
  42048, //1994
  43329, //1995
  44610, //1996
  45891, //1997
  48224, //1998
  49411, //1999
  49836, //2000
  50837, //2001
  52077, //2002
  53338, //2003
  54592, //2004
  55858, //2005
  57230, //2006
  58628, //2007
  60035, //2008
  61196, //2009
  62572, //2010
  63996, //2011
  65387, //2012
  67002, //2013
  67265, //2014
  67382, //2015 - 1799명
  69078, //2016 - 1895명
  70878, //2017 - 1868명
  72747, //2018 - 1839명
  74587, //2019 - 1896명
  76484, //2020 - 1936명
  78421, //2021 - 1748명
  80170, //2022 - 1840명
  82011, //2023 - 1887명
  83900, //2024 - 1879명
  85780, //2025
];
